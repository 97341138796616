.txtBox {
  padding-top: 20rem;
  display: flex;
  width: 33rem;
  height: 5.4375rem;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
}
.intro {
  color: #f2e9e9;
  text-align: center;
  font-size: 3rem;
  font-family: Inter;
  font-weight: 700;
}
.bgimg {
  padding-top: 4rem;
  margin-left: 6rem;
  position: absolute;
  position: fixed;
}
.bg {
  width: 60%;
  height: auto;
}

.redLetter {
  color: #8dcbe6;
}
.jobs {
  color: #8dcbe6;
}
.intro {
  position: relative;
}
.hi {
  color: #f2e9e9;
}
