.navbar {
  width: 100%;
  height: auto;
  position: fixed !important;
  z-index: 2;
}
.nav-link:hover {
  color: #8dcbe6 !important;
}

.dropdown-item:hover {
  background: #8dcbe6 !important;
}
.rabBox {
  height: 100%;
  width: auto;
  border-radius: 92px;
  background: url(./top.png), rgb(80, 78, 78) 50% / cover no-repeat;
  box-shadow: 0px 4px 4px 0px rgba(17, 16, 16, 0.25) inset;
  transition: 0.5s ease;
}
.rabBox:hover {
  background: url(./top.png), #5e8ca0 50% / cover no-repeat;
}
.rab {
  margin-left: 15px;
  height: 40px;
  width: auto;
  transition: 0.5s ease;
}
.rab:hover {
  transform: scale(1.1);
}
